<template>
    <div class="card card-primary card-outline">
        <div class="card-header">
            <div class="card-title">
                Streckenplanung
            </div>
            <div class="card-tools">
                <router-link :to="{name: 'routes.create'}" class="btn btn-primary btn-sm mr-1" v-if="$auth.check('routes.create')">
                    <i class="fas fa-plus"></i> Streckenplanung erstellen
                </router-link>
                <button class="btn btn-default" @click="loadRoutes()">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-hover table-sm">
                <thead>
                    <tr>
                        <th>Nr.</th>
                        <th>Bezeichnung</th>
                        <th>Fahrzeug</th>
                        <th>geplanter Tag</th>
                        <th>Status</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(route, index) in routes.data" :key="route.id">
                        <td>{{index+1}}</td>
                        <td>{{route.name}}</td>
                        <td>
                            <template v-if="route.car != null && route.car != ''">
                                {{route.car.name}}
                            </template>
                            <template v-else>
                                ---
                            </template>
                        </td>
                        <td>
                            <template v-if="route.deliveryDay != null && route.deliveryDay != ''">
                                {{route.deliveryDay | formatDate}}
                            </template>
                            <template v-else>
                                ---
                            </template>
                        </td>
                        <td>
                            <span>
                                <template>
                                    <span class="badge" :class="setBadge(route.status.name)">{{route.status.description}}</span>
                                </template>
                            </span>
                        </td>
                        <td>
                            <button class="btn btn-primary btn-xs mr-1" v-if="route.media != null && route.media != ''" @click="downloadPicklist(route)">
                                <i class="fas fa-fw fa-download"></i>
                            </button>
                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'routes.show', params: {id: route.id}}" v-if="$auth.check('routes.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'routes.details', params: {id: route.id}}" v-if="$auth.check('routes.edit') && (route.status.name != 'completed' && route.status.name != 'rework')"><i class="fas fa-fw fa-edit"></i></router-link>
                            <button v-if="$auth.check('routes.destroy')" @click="deleteRoute(route.id)" class="btn btn-danger btn-xs">
                                <i class="fas fa-fw fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer" v-if="routes.data != null">
            <template v-if="routes.data.length > 0">
                <div class="row align-items-center">
                    <div class="col-md-9">
                        <pagination class="float-left" :data="routes" @pagination-change-page="loadRoutes" :limit="3"></pagination>
                    </div>
                    <div class="col-md-2">
                        <span class="float-right">Anzeige Eintrag {{ routes.meta.from }} - {{ routes.meta.to }} von {{ routes.meta.total }}</span>
                    </div>
                    <div class="col-md-1">
                        <select class="float-right form-control form-control-sm" v-model="params.per_page">
                            <option value="25">25 Artikel</option>
                            <option value="50">50 Artikel</option>
                            <option value="75">75 Artikel</option>
                            <option value="100">100 Artikel</option>
                            <option value="125">125 Artikel</option>
                            <option value="150">150 Artikel</option>
                        </select>
                    </div>
                </div>                        
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RouteIndex',
    title: 'Routenplanung',

    data() {
        return {
            routes: [],
            states: [],
            selectedRow: {},
            selectedCell: null,
            page: 1,
            params: {
                per_page: 25
            }
        };
    },

    watch: {
        params: {
            handler() {
                this.loadRoutes();
            },
            deep: true
        }
    },

    methods: {
        downloadPicklist(route)
        {
            this.axios
                .get("/routes/" + route.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response, route)
                    this.loadRoutes();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        downloadFile(response, route){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Pfand-' + route.id + '.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },
        loadRoutes(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/routes', {
                    params: {
                        page: page,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.routes = response.data;
                    // console.log(this.routes);
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        count(route) {
            let count = 0;
            route.route_elements.forEach(element => {
                if(element.status.name == 'completed')
                {
                    count++;
                }
            });
            return count;
        },
        countStates(route) {
            var count = this.count(route);
            if(count == 0)
            {
                return 'badge-danger'
            } else
            {
                return 'badge-warning'
            }
        },
        setBadge(status)
        {
            if(status == 'open')
            {    
                return "badge-primary";
            }
            else if(status == 'on_the_way')
            {
                return 'badge-warning';
            }
            else if(status == 'rework')
            {
                return 'badge-danger';
            }
            else
            {
                return 'badge-success'
            }
        },
        deleteRoute(route_id) {
            this.$swal({
                title: "Möchtest du die Route wirklich löschen",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/routes/" + route_id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Streckenplanung gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadRoutes();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },
        loadStates() {
            this.axios
                .get("/states/route")
                .then((response) => {
                    this.states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schiefgelaufen.'
                    });
                })
        },
        handleEditCell(index, name) {
            // console.log("Test");
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },
        saveRoute(route)
        {
            this.axios
                .put("/routes/" + route.id, {
                    id: route.id,
                    car_id: route.car_id,
                    name: route.name,
                    deliveryDay: route.deliveryDay,
                    status_id: route.status_id
                })
                .then(() => {
                    this.loadRoutes();
                    this.selectedRow = {};
                    this.selectedCell = null;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        }
    },

    mounted() {
        this.loadRoutes();
        this.loadStates();
    }
}
</script>

<style>

</style>